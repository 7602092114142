.menu-items-search{

}

.menu-items-list{
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 5px;
        border-radius: 5px;
    }
}

.dropdownList{
    max-height: 230px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 5px;
        border-radius: 5px;
    } 
}