.headerContainer {
    box-sizing: border-box;
    border-bottom: 1px solid rgb(229, 225, 236);
    background: rgb(255, 255, 255);
    // position: absolute;
    width: 100%;
    height: 48px;
    left: 0;
    right: 0;
    // top: 71px;
    // bottom: -71px;
    justify-content: start;
    padding-left: 40px;
}

.container{
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}

.mainDiv{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
    margin-bottom: 30px;
}

.leftsideDiv{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.smallCards{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  
    .smallCard{
        width: 178px;
        padding: 18px 12px;
        background: #FFFFFF;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        flex-grow: 1;
        box-sizing: border-box;
    }
}

.custom-table-header{
    display: flex;
    gap: 20px;
    padding: 6px;
    border-bottom: 1px solid #FFD8E8;
}

.custom-table-body{
    overflow: hidden;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}

.custom-table-row{
    display: flex;
    gap: 20px;
    padding: 6px;
}

.custom-table-row:hover{
    background-color: #F4F0FE;
    border-radius: 5px 0px 0px 0px;
}

.right-div{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    gap: 16px;
}

.expand-right-div{
    display: none;
}

.segmentation-cards{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.segment-card{
    padding: 8px;
    border-radius: 5px 0px 0px 0px;
    border: 1px solid #E5E1EC;
    display: flex;
    flex-direction: column;
    gap: 18px;
    .title{
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        color: #130F35;
    }
    .tiles{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.expand-salestree{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.salestree-second-expand{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    gap: 16px;
}

.remaining-charts-exapnd{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
}