:global(.MuiInputBase-root.Mui-error.Mui-focused) {
  caret-color: #d32f2f;
}

:global(.MuiInputBase-root.Mui-focused) {
  caret-color: #1976d2;
}

:global(.MuiFormHelperText-root.Mui-error) {
  margin-left: 0;
}

.textbox {
  &Filter {
    :global(.MuiInputBase-input) {
      cursor: pointer;
    }

    :global(.MuiFormLabel-root),
    :global(.MuiFormLabel-root.Mui-focused) {
      color: #000;
    }

    :global(.MuiInputBase-root:after) {
      border-bottom-color: #000;
    }

    :global(.MuiInputBase-root:not(.Mui-disabled, .Mui-error):before),
    :global(.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before) {
      border-bottom: 1px solid #000 !important;
    }
  }
}

:global(.MuiInputBase-input.MuiOutlinedInput-input) {
  padding: 12px 14px;
}

:global(.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

:global(.MuiInputBase-input.MuiInput-input) {
  font-size: 14px;
}

:global(.css-1d1r5q-MuiFormHelperText-root) {
  margin-top: 0 !important;
  height: 0 !important;
  margin-bottom: 5px !important;
}

:global(.MuiFormHelperText-sizeMedium) {
  margin-top: 0 !important;
  height: 7px !important;
  margin-bottom: 0px !important;
}

:global(.readOnly-text input) {
  background-color: #e9ecef;
  pointer-events: none;
  opacity: 1;
  -webkit-text-fill-color: #000 !important;
}

:global(.MuiFormHelperText-root.Mui-error) {
  font-size: 75%;
}