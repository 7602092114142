.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.text {
  font-weight: 600;
  font-size: 16px;
  font-family: poppins;
}

.mainCont {
  display: flex;
  justify-content: start;
}

.headerText {
  color: rgb(16, 16, 18);
  font-family: Poppins !important;
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  text-align: left;
}

.fontStyles {
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: 0px;
  text-align: left;
}