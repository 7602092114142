.card-container{
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    background: rgb(255, 255, 255);
    .card-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid rgb(205, 198, 255);
        .card-title{
            color: rgb(36, 37, 51);
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0px;
        }
    }
}