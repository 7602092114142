.sidebarInsightsHeader {
  position: relative;
  width: 260px;
  height: 49px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 12px 81px 12px 81px;
  // box-sizing: border-box;
  border-bottom: 1px solid rgb(229, 225, 236);
}

.sidebarRightSideContainer {
  border-right: 1px solid rgb(229, 225, 236);
  // height: 100vh;
  // overflow: auto;
}

.insightsHeader {
  color: rgb(38, 27, 113);
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
}

.sidebar {
  position: fixed;
  top: 70px;
  left: 0;
  height: 100%;
  width: 260px;
  background: #fff;
  z-index: 1;
  transition: all 0.5s ease;
}

.sidebar.close {
  width: 78px;
}

 .logo-details {
  height: 70px;
  width: 260px;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(229, 225, 236);
}

.logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 20px;
  text-align: center;
  line-height: 50px;
}

 .logo-details .collapse-btn {
  position: relative;
  right: 10px;
  transition: all 0.5s ease;
}

 .logo-details .close-sidebar-btn {
  position: relative;
  left: 40px;
  transition: all 0.5s ease;
}

.sidebar .arrow-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #302f37;
}

.sidebar .logo-details .close-sidebar-btn svg {
  transform: rotate(-180deg);
}

.sidebar .logo-details svg {
  font-size: 10px;
  font-weight: bolder;
  color: #fff;
  text-align: center;
  line-height: 50px;
  min-width: 0px;
}

.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  transition-delay: 0.1s;
}

.sidebar .logo-details .hide-logo {
  display: none;
}

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.sidebar.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
  background: #1d1b31;
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sidebar.close .nav-links li .iocn-link {
  display: block
}

.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li p {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-links li p .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}

.sidebar .nav-links li p .link_name.submenu-header-none {
  display: none;
}

.sidebar .nav-links li p .link_name.submenu-header-show {
  display: block;
  color: #fff;
}

.sidebar.close .nav-links li p .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu p {
  &.submenu-header {
    opacity: 1;
    cursor: default
  }

  ;
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu p:hover {
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.home-section {
  position: relative;
  background: #F5F5F5;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // padding: 12px;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(229, 225, 236);
  height: 70px;
}

.home-content {
  .logo_name {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
    margin-left: 10px;
  }

  .hide-logo {
    display: none;
  }
}

img.profile {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.home-content .navbar_content {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  margin-right: 23px;
}

@media screen and (max-width: 400px) {
  .sidebar {
    width: 240px;
  }

  .sidebar.close {
    width: 78px;
  }

  .home-section {
    left: 240px;
    width: calc(100% - 240px);
  }

  .sidebar.close~.home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
}