.fontColorGrey {
  color: rgb(94, 92, 113);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: center;
  margin-top: 1px;
}



.action {
  // background: red;
  color: var(--gray-700, #344054);
  /* Text sm/Medium */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.containerPagination {
  display: flex;
  align-items: center;
  background: inherit;
}

.paginationButton {
 display: flex !important;
 justify-content: center !important;
 align-items: center !important;
}

.paginationButton button {
  font-size: 12px !important;
  line-height: 14px !important;
}
.paginationRowsPerPage {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  padding: 8px 16px !important;
  height: 36px !important;
}

@media screen and (min-width: 1536px) and (max-width: 1720px) {
  .paginationShow {
    font-size: 11px;
    line-height: 12px;
  }

  .action {
    font-size: 11px;
    line-height: 12px;
  }

  .containerPagination {
    height: 50px !important;
  }

  .paginationButton button {
    font-size: 11px !important;
    line-height: 12px !important;
    padding:4px 8px !important;
    height: 35px !important;
  }
  .paginationRowsPerPage {
    font-size: 11px !important;
    line-height: 18px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1536px) {
  .paginationShow {
    font-size: 10px;
    line-height: 10px;
  }

  .action {
    font-size: 10px;
    line-height: 10px;
  }

  .containerPagination {
    height: 50px !important;
  }

  .paginationButton button {
    font-size: 10px !important;
    line-height: 10px !important;
    padding:2px 6px !important;
    height: 30px !important;
  }

  .paginationRowsPerPage {
    font-size: 10px !important;
    line-height: 16px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .paginationShow {
    font-size: 8px;
    line-height: 10px;
  }

  .action {
    font-size: 8px;
    line-height: 10px;
  }

  .containerPagination {
    height: 50px !important;
  }

  .paginationButton button {
    font-size: 8px !important;
    line-height: 10px !important;
    padding:2px 6px !important;
    height: 30px !important;
  }

  .paginationRowsPerPage {
    font-size: 8px !important;
    line-height: 10px !important;
  }
}
