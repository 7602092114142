.headerContainer {
    box-sizing: border-box;
    border-bottom: 1px solid rgb(229, 225, 236);
    background: rgb(255, 255, 255);
    width: 100%;
    height: 48px;
    left: 0;
    right: 0;
    justify-content: start;
    padding-left: 40px;
}

.bestSellingHeader {
    color: rgb(19, 15, 53);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    width: 240px ;
    // margin-top: 15px;
}

.create-button{
    position: absolute;
    right: 20px;
    bottom: 100px;
    button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 10px 21px 10px 21px;
        border-radius: 100px;
        box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
        background: rgb(19, 15, 53);
        text-transform: none;
        color: #fff;
        &:hover{
            background: rgb(19, 15, 53);
        }
    }
}

.form{
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    padding: 24px;
    height: calc(100vh - 270px);
    overflow: auto;
    padding-bottom: 70px;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
      
      /* For styling the scrollbar thumb */
      &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
      }
    .left-form{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .input{
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            .form-label{
                color: rgb(19, 15, 53);
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.1px;
            }
        }
        .input-date{
            width: 223px;
            height: 36px;
            border: 1px solid rgb(94, 92, 113);
            border-radius: 5px;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
            padding: 8px;
            &::placeholder{
                color: rgb(94, 92, 113);
            }
        }
    }
    .form-label{
        color: rgb(19, 15, 53);
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
}

.footer-buttons{
    display: flex;
    gap: 30px;
    align-items: center;
    height: 64px;
    position: absolute;
    bottom: 100px;
    left: 40px;
    background: #fff;
    width: 100%;
}

.rule-content{
    border-radius: 5px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
    background: rgb(244, 240, 254);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title{
        color: rgb(19, 15, 53);
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .mini-title{
        color: rgb(48, 36, 139);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .content{
        color: rgb(0, 0, 0);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .content-list{
        list-style-type: decimal;
        margin-left: 17px;
        li{
            color: rgb(0, 0, 0);
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }
}