.mainCard{
    background: #FFFFFF;
    border-radius: 5px 0px 0px 0px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
    // height: 350px;
}

.cardHeader{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FFD8E8;
    padding: 16px;
    align-items: center;
    .title{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #222529;
    }
}

.expand{
    grid-column: span 3;
}

.scroll-styles{
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}

@media (max-width: 1500px) {
    .scroll-styles{
        max-height: calc(100vh + 150px);
    }
}