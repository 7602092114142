.heightContainer {
    height: calc(100vh - 200px) !important;
    overflow: auto;
    // border: 1px solid rgb(225, 225, 225);
    background: rgb(254, 254, 254);

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}


.bestSellingHeader {
    color: rgb(19, 15, 53);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    width: 220px !important;
    // margin-top: 15px;
}

.loyalityHeader{
    color: rgb(19, 15, 53);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
}


.periodName {
    color: rgb(19, 15, 53);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
}


.underLineText {
    color: rgb(95, 70, 254);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: center;
    text-decoration-line: underline;
}