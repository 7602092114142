.headerContainer {
    box-sizing: border-box;
    border-bottom: 1px solid rgb(229, 225, 236);
    background: rgb(255, 255, 255);
    // position: absolute;
    width: 100%;
    height: 48px;
    left: 0;
    right: 0;
    // top: 71px;
    // bottom: -71px;
    justify-content: start;
    padding-left: 40px;
}

.bestSellingHeader {
    color: rgb(19, 15, 53);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    width: 220px ;
    // margin-top: 15px;
}