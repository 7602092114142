.MainTextHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 26px 12px 26px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(239, 239, 241);
    background: rgb(252, 248, 255);
    color: rgb(19, 15, 53);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
}

.scrollContainer {
    height: calc(100vh - 130px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}

.scrollContainer2 {
    height: calc(100vh - 250px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}

.subLabelText {
    color: rgb(94, 92, 113);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
    list-style: none;
}