.datatable {
    background-color: rgb(255, 255, 255);
    font-family: Poppins !important;

    &Header {
        border-radius: none !important;
        th {
            height: 60px !important;
            padding: 0px;
            font-family: Poppins !important;
            color: rgb(24, 18, 74);
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
            border-radius: none !important;
        }

    }

    &Body {
        tr {
            height: 55px !important;
            align-items: center !important;
            justify-content: center !important;
            padding: 0px !important;

            td {
                height: 55px !important;
                padding: 0px !important;
                   align-items: center !important;
                justify-content: center !important;
                color: rgb(0, 0, 0);
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.1px;
            }
        }
    }

    &Checkbox {
        width: 20px;
        height: 20px;
    }
}

.orderTableContainer {
    height: calc(100vh - 300px) !important;
    overflow: auto;
}

.customRowStyles {
    background: #FFF !important;
}


@media screen and (min-width: 1536px) and (max-width:1720px) {
    .datatable {
        background-color: #FFF;

        &Header {
            th {
                height: 50px;
                padding: 0px;
                font-size: 11px !important;
                line-height: 20px !important;
            }

        }

        &Body {
            tr {
                height: 40px !important;
                align-items: center !important;
                padding: 0px !important;

                td {
                    height: 40 !important;
                    padding: 0px !important;
                    font-size: 11px !important;
                    line-height: 12px !important;
                    align-items: center !important;
                }
            }
        }

        &Checkbox {
            width: 20px;
            height: 20px;
        }
    }
}


@media screen and (min-width: 1400px) and (max-width:1536px) {
    .datatable {
        background-color: #FFF;

        &Header {
            th {
                height: 40px;
                padding: 0px;
                font-size: 10px !important;
                line-height: 18px !important;
            }

        }

        &Body {
            tr {
                height: 40px !important;
                align-items: center !important;
                padding: 0px !important;

                td {
                    height: 30px !important;
                    padding: 0px !important;
                    font-size: 10px !important;
                    line-height: 10px !important;
                    align-items: center !important;
                }
            }
        }

        &Checkbox {
            width: 20px;
            height: 20px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .RightContainerHeader {
        font-size: 10px,
    }
}

@media screen and (min-width: 1200px) and (max-width:1400px) {
    .datatable {
        background-color: #FFF;

        &Header {
            th {
                height: 30px !important;
                padding: 0px;
                font-size: 8px !important;
                line-height: 18px !important;
            }

        }

        &Body {
            tr {
                height: 28px !important;
                align-items: center !important;
                padding: 0px !important;

                td {
                    height: 25px !important;
                    padding: 0px !important;
                    font-size: 8px !important;
                    line-height: 8px !important;
                    align-items: center !important;
                }
            }
        }

        &Checkbox {
            width: 20px;
            height: 20px;
        }
    }
}