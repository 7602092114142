@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-poppins {
  font-family: Poppins;
}

.jvectormap-container:nth-of-type(2) {
  display: none;
}

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

/* For styling the scrollbar thumb */
&::-webkit-scrollbar-thumb {
  background-color: #EAECF0;
  width: 8px;
  border-radius: 8px;
}

& .MuiList-root.MuiMenu-list {
  padding: 0px !important
}

.MuiPopover-root {
  z-index: 100000 !important;
}

.scrollContainer55 {
  height: calc(100vh - 250px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  /* For styling the scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0;
    width: 8px;
    border-radius: 8px;
  }
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  /* For styling the scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0;
    width: 8px;
    border-radius: 8px;
  }
}