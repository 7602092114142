@import '../../../assets/scss/base/base.scss';

.button {
  &:global(.MuiButtonBase-root) {
    text-transform: capitalize;
    font-weight: 500;
    margin: 0px 0px 0px 4px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: rgb(95, 70, 254);
    color: #ffffff;
    box-shadow: 2px 2px 8px 1px rgba(16, 24, 40, 0.12);

    &:disabled {
      background-color: rgba(61, 58, 211, 0.2);
    }

    &:hover {
      box-shadow: none;
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%),
        rgb(95, 70, 254);
    }

    &:focus {
      background-color: rgb(95, 70, 254);
    }
  }
}