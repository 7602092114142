.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.text {
  font-weight: 600;
  font-size: 16px;
  font-family: poppins;
}

.mainCont {
  display: flex;
  justify-content: start;
}

.headerText {
  color: rgb(16, 16, 18);
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
}

.fontStyles {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
}

.mainLoginCont {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  position: absolute;
  width: 540px;
  height: 563px;
  // left: 450px;
  // top: 231px;
  /* Auto layout */
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 72px 48px 72px;
}


.mainLoginText{
  color: rgb(16, 16, 18);
font-family: Poppins;
font-size: 28px;
font-weight: 600;
line-height: 100%;
letter-spacing: 0%;
text-align: left;
z-index: 99999999 !important;
margin-bottom: 30px;
}



.sizeStyles{
  border-radius: 8px;
/* 2px */
box-shadow: 0px 0px 1px 0px rgba(15, 69, 193, 0.15),0px 0px 4px -1px rgba(15, 69, 193, 0.2);
/* Light Mode/White/white-50 */
background: rgb(255, 255, 255);
height:52px

}