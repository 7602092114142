.listviewContainer {
    box-sizing: border-box;
    /* Xylic Light mode/Neutrals/white-600 */
    border-radius: 5px;
    /* Xylic Light mode/Neutrals/white-50 */
    padding: 10px;
    border:none
}

.heightContainer {
    height: calc(100vh - 300px) !important;
    overflow: auto;
    // border: 1px solid rgb(225, 225, 225);
    background: rgb(254, 254, 254);
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
    }

    /* For styling the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #EAECF0;
        width: 8px;
        border-radius: 8px;
    }
}


.cardContainer1 {
    box-sizing: border-box;
    border: 1px solid rgb(255, 216, 232);
    border-radius: 5px;
    // width:271px;
    height: 125px;
    padding: 10px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    cursor: pointer;
}

.cardAlignments {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.cardNameText {
    color: rgb(8, 6, 21);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
}

.cardDescText {
    color: rgb(8, 6, 21);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
}