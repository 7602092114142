.insightsHeader {
  color: rgb(38, 27, 113);
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
}

.canvas {
  background-image: radial-gradient(rgb(38, 27, 113) 5%, #F5F5F5  5%) !important;
}
