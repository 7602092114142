.searchInput{
    position: static;
width: 265px;
height: 37px;
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
padding: 4px;

/* Inside Auto Layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 4px;
}